var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-dropdown",
    {
      attrs: { placement: "bottomRight" },
      scopedSlots: _vm._u([
        {
          key: "overlay",
          fn: function () {
            return [
              _c(
                "a-menu",
                {
                  staticClass: "ant-pro-drop-down menu",
                  attrs: { "selected-keys": [] },
                },
                [
                  _vm.$access("ENT_C_USERINFO")
                    ? _c(
                        "a-menu-item",
                        {
                          key: "settings",
                          on: { click: _vm.handleToSettings },
                        },
                        [
                          _c("a-icon", { attrs: { type: "setting" } }),
                          _vm._v(" 账户设置 "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("a-menu-divider"),
                  _c(
                    "a-menu-item",
                    { key: "logout", on: { click: _vm.handleLogout } },
                    [
                      _c("a-icon", { attrs: { type: "logout" } }),
                      _vm._v(" 退出登录 "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "span",
        { staticClass: "ant-pro-account-avatar" },
        [
          _c("a-avatar", {
            staticClass: "antd-pro-global-header-index-avatar",
            attrs: { size: "small", src: _vm.greetImg },
          }),
          _c("span", [_vm._v(_vm._s(_vm.currentUserName))]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }