import router from './router'
// import {getToken} from '@/utils/auth' // get token from cookie
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import { setDocumentTitle } from '@/utils/domUtil'
import appConfig from '@/config/appConfig'
const whiteList = ['/register','/user/login','/','/check','/stampui','/modify'] // no redirect whitelist
NProgress.configure({ showSpinner: false }) // NProgress Configuration

// 路由守卫
router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar

  to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${appConfig.APP_TITLE}`)) // 设置浏览器标题

  const hasToken =localStorage.getItem("setToken") //getToken()
  // console.log(hasToken,'hasToken+++++++')
  if (hasToken) {
    if (to.path === '/user/login') {
      next({ path: '/orderlist' })
      NProgress.done()
    } else {
      next()
    }
  } else {
    if(to.path === '/'){
      next()
      NProgress.done()
    }else{
      if (whiteList.indexOf(to.path) !== -1) {
        next()
      } else {
        next(`/user/login`)
        NProgress.done()
      }
    }

  }
    // next()
    // NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    // return false
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
