var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-config-provider", { attrs: { locale: _vm.locale } }, [
    _c("div", { attrs: { id: "app" } }, [_c("router-view")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }