<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import Loading from "./components/GlobalLoad/GlobalLoad"; // 全局Loading组件
import { mapState } from "vuex";
import {Company_getInfo, conf_list} from "@/api/manage"; // 引入vuex状态管理，mapState管理中存在全局loading
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  created() {
    conf_list({}).then((response) => {
      var obj = response.data;
      obj = JSON.stringify(obj);
      localStorage.setItem('configdata', obj)
    });
    Company_getInfo({}).then(response => {
      var obj = response.user;
      obj = JSON.stringify(obj);
      localStorage.setItem('companyinfo', obj)
    })
  },
  components: {
    Loading, // 注册全局loading 组件
  },
  computed: {
    // 全局 loading
    ...mapState(["globalLoading"]),
  },
};
</script>
