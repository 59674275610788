var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["user-layout-wrapper"], attrs: { id: "userLayout" } },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "user-layout-content" },
          [_c("router-view"), _vm._m(0)],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "links" }, [
        _c("a", { attrs: { href: "_self" } }, [_vm._v("帮助")]),
        _c("a", { attrs: { href: "_self" } }, [_vm._v("隐私")]),
        _c("a", { attrs: { href: "_self" } }, [_vm._v("条款")]),
      ]),
      _c("div", { staticClass: "copyright" }, [
        _vm._v(" Copyright © 2021 jeequan.com "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }