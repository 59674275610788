var render, staticRenderFns
import script from "./RouteView.vue?vue&type=script&lang=js"
export * from "./RouteView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\xm\\GitHub\\certbuykunxiang\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02b43ddf')) {
      api.createRecord('02b43ddf', component.options)
    } else {
      api.reload('02b43ddf', component.options)
    }
    
  }
}
component.options.__file = "src/layouts/RouteView.vue"
export default component.exports